<template>
    <div id="contacts-form-section"> 
      <contacts-list-form-section></contacts-list-form-section>
    </div>
  </template>
  
  <script>
    import ContactsListFormSection from './components/ContactsListFormSection'
  
    export default{
      name: 'ContactsFormSection',
      components: {ContactsListFormSection},
      beforeMount() {
        
      },
      methods: {
       
      },
    }
  </script>